/* CreateFormPage.css */

.survey-form-container {
    width: 50%;
    margin: auto;
}

.create-form-container {
    padding: 20px;
    margin-left: 2%;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: calc(100% - 40.5%);
    overflow-y: auto;
}

h2,
h3 {
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Gelion Bold';
    font-size: 24px;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field,
.textarea-field,
.select-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.textarea-field {
    resize: none;
}

.char-count {
    font-size: 12px;
    color: #999;
    text-align: right;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-right: 10px;
    border-radius: 6px;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}

.add-option-button {
    background: #4caf50;
    border: 1px solid #4caf50;
}

.add-option-button:hover {
    background-color: #45a049;
    border-color: #45a049;
}

.question-group {
    margin-bottom: 20px;
}

.question-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.add-question-button,
.remove-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #e9f1fc;
    color: #000;
    border: 1px solid #e9f1fc;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 6px;
}

.add-question-button:hover,
.remove-button:hover {
    background-color: #d3e2fd;
    border-color: #d3e2fd;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.switch-container {
    display: flex;
    gap: 10px;
    flex-direction: inherit;
}

.switch-label {
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #000;
}

input:checked+.slider:before {
    transform: translateX(14px);
}