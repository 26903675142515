/* ExcelView.css */

@import './fonts.css';


.main-content, .dashboard{
    background: #fff !important;
    overflow: hidden;
}

.excel-panel-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    z-index: 10; /* Ensure it stays on top */
}

.excel-panel {
    display: flex;
    gap: 10px;
}

.filter-section {
    display: flex;
    gap: 10px;
}

.input-field,
.filter-select {
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.excel-panel-button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 13px;
}

.excel-panel-button:hover {
    background: #eee;
}

.excel-panel-icon {
    margin-right: 5px;
}

.excel-view {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 2%;
    height: 100vh; /* Adjust the height to fill the viewport */
    overflow: hidden; /* Prevent scrolling on the entire page */
}

.report-header {
    padding: 10px 0;
    border-bottom: 1px solid #e9e9eb;
    margin-bottom: 20px;
}

.report-title {
    font-family: 'Gelion Bold';
    font-size: 24px;
    margin: 0;
}

.report-description {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
    margin-top: 5px;
}

.table-container {
    flex-grow: 1; /* Allow the table container to grow and take up available space */
    overflow-y: auto; /* Make the table scrollable */
    margin-bottom: 2%; /* Add space at the bottom to avoid overlap with pagination */
    overflow-x: auto; /* I have added */
}

table {
    width: 100%;
    border-collapse: collapse;
    border-color: aqua;
    table-layout: auto; /* change to auto */
    /* table-layout: fixed; /* Ensures the table does not overflow */
    user-select: none; /* Prevent text selection in the table */
    position: relative;
}

th,
td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-family: 'Gelion Regular';
    font-size: 14px;
    border: 1px solid #e9e9eb;
    word-wrap: break-word; /* Ensure long text wraps within the cell */
    white-space: pre-wrap;   /* Allow text to wrap within the header */
}

thead {
    background-color: #f7f7f8;
    position: sticky;
    top: 0;
    z-index: 5; /* Ensure the table header stays on top */
}

th {
    position: relative;
    white-space: nowrap;
}

.column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 15px; /* Add padding to accommodate the resizer */
    word-wrap: break-word; /* Ensure long text wraps within the header */
    white-space: normal;   /* Allow text to wrap within the header */
}

th svg {
    margin-left: 5px;
    flex-shrink: 0;
}

.resizer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
    z-index: 1;
    background-color: transparent;
}

.resizer:hover {
    background-color: #ddd;
}

.loader {
    font-family: 'Gelion Regular';
    font-size: 16px;
    color: #333;
    text-align: center;
    padding: 20px;
}

.pagination {
    display: flex;
    justify-content: space-between; /* Adjust space between items per page and pagination */
    margin-top: 20px;
    padding: 10px 0;
    z-index: 10; /* Ensure pagination stays on top */
    position: sticky;
    bottom: 0;
}

.pagination-button,
.pagination-dots {
    border: 1px solid #ccc;
    background-color: #f7f7f8;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
    font-family: 'Gelion Regular';
    font-size: 13px;
    border-radius: 4px;
}

.pagination-button:hover {
    background-color: #e9e9eb;
}

.pagination-button.active {
    background-color: #333;
    color: white;
    border-color: #333;
}

.pagination-dots {
    padding: 5px 10px;
    cursor: default;
}

.items-per-page {
    display: flex;
    align-items: center;
    font-family: 'Gelion Regular';
    font-size: 13px;
}

.items-per-page-select {
    margin-left: 5px;
    padding: 5px;
    font-size: 13px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.column-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.column-selector-item {
    margin-right: 15px;
    margin-bottom: 10px;
    font-family: 'Gelion Regular';
    font-size: 14px;
    display: flex;
    align-items: center;
}

.column-selector-item input {
    margin-right: 5px;
}

.filter-dropdown-wrapper {
    position: relative;
    display: inline-block;
}

.filter-dropdown-icon {
    cursor: pointer;
    margin-left: 5px;
}

.filter-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    width: max-content;
}

.filter-dropdown div{
    margin-bottom: 10px;
}

.filter-dropdown input[type="checkbox"] {
    margin-right: 5px;
}

.filter-dropdown label {
    font-family: 'Gelion Regular';
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
}



input[type="checkbox"]:checked {
    background-color: #333;
    border-color: #333;
    position: relative;
}

.view-report-link{
    text-decoration: underline;
    color: #333;
}

.view-report-link:hover{
    color: #000;
    font-weight: 600;
}

.no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: #333;
}

.no-data-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.no-data-text {
    font-family: 'Gelion Bold';
    font-size: 18px;
    margin-bottom: 0;
}

.no-data-subtext {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
}

.view-report-link {
    text-decoration: underline;
    color: #333;
}

.view-report-link:hover {
    color: #000;
    font-weight: 600;
}

.action-column {
    text-align: justify;    
}

.action-link {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
}

.action-link:hover {
    color: #000;
    font-weight: bold;
}

/* Floating button for AI Component */
.ai-toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
}

.ai-toggle-button:hover {
    background-color: #000;
}

/* AI Component Modal */
.ai-chatbot-container {
    position: fixed;
    bottom: 80px; /* Just above the floating button */
    right: 20px;
    width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
}

.ai-chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    color: white;
    font-family: 'Gelion Bold';
}

.ai-chatbot-body {
    padding: 15px;
}

.ai-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ai-submit-button {
    padding: 8px 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ai-submit-button:hover {
    background-color: #000;
}

.ai-close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}

.ai-response {
    margin-top: 15px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
}


.ai-component.hidden {
    display: none;
}

.ai-component.visible {
    display: block;
}

.ai-component {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
}

.ai-header {
    display: flex;
    justify-content: flex-end;
}

.ai-floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.ai-floating-button:hover {
    background-color: #555;
}


/* Modal Styles */
.custom-modal {
    background: #fff;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    border-radius: 8px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.custom-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #333;
}

.modal-close-button:hover {
    background: none;
    color: #000;
}

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.modal-submit-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.modal-submit-button:hover {
    background-color: #000;
}

/* DatePicker Styles */
.react-datepicker-wrapper {
    width: 100%;
}

.input-field {
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

/* Modern Date Range Picker Styles */
.date-range-picker {
    display: flex;
    flex-direction: initial;
    gap: 15px;
    margin-bottom: 20px;
    padding-top: 15px;
}

.date-range-picker label {
    font-family: 'Gelion Bold';
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.date-range-picker .input-field {
    width: 40% !important;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.date-range-picker .input-field:focus {
    border-color: #555;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
}

.mar-t--10{
    margin-top: -10px;
}

.mar-b-0{
    margin-bottom: 0px;
}

.button-gap {
    margin-right: 10px;
}

.modal-input-label{
    margin-bottom: 14px !important;
    font-family: 'Gelion Regular';
    font-size: 16px;
}

.parameter-group{
    margin-top: 0;
    margin-bottom: -0;
}


/* For react-select dropdown */
.react-select__control {
    padding: 0;
    font-size: 14px;
    font-family: 'Gelion Regular';
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.react-select__menu {
    z-index: 20; /* Ensure the dropdown menu is visible above other elements */
}

.react-select__option {
    padding: 8px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.react-select__option--is-focused {
    background-color: #f7f7f8; /* Add hover effect */
}

.react-select__option--is-selected {
    background-color: #333;
    color: white;
}

.search-select-field {
    padding: 0 !important;
    border: none !important;
}

.validation-error-message{
    margin-top: 20px;
    color: red;
    font-size: 14px;
}


/* Card View Styles */
.card-view-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: flex-start;

    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 2%;
    overflow-x: auto;
}

.report-card {
    width: 300px;
    padding: 20px;
    border: 1px solid #f8f8f8;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

.report-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.report-title {
    font-family: 'Gelion Bold' !important;
    font-size: 18px !important;
    color: #333 !important;
    margin-bottom: 10px !important;
}

.report-description {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 20px;
}



/* View Switch Button Styles */
.view-switch {
    margin-bottom: 20px;
    text-align: right;
}

.view-switch-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
}

.view-switch-button:hover {
    background-color: #000;
}

.view-switch-icon {
    margin-right: 10px;
}




.sum-result {
    font-family: 'Gelion Regular';
    font-size: 16px;
    color: #333;
    margin-top: 20px;
}

.context-menu {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    cursor: pointer;
    font-family: 'Gelion Regular';
    font-size: 14px;
    border-radius: 4px;
}

.context-menu:hover {
    background-color: #f0f0f0;
}


.selected-cell {
    background-color: #fffaea;
    border: 1px solid #ddd;
}

.context-menu {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    cursor: pointer;
    font-family: 'Gelion Regular';
    font-size: 14px;
    border-radius: 4px;
}

.context-menu:hover {
    background-color: #f0f0f0;
}


.hide-grid th,
.hide-grid td {
    border: none;
}

.menu-wrapper {
    position: relative;
    display: inline-block;
    top: 11px;
}

.three-dot-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    color: #333;
}

.menu-dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    width: 200px;
    padding: 10px;
}

.menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #333;
}

.menu-item:hover {
    background-color: #f7f7f8;
}

.toggle-switch {
    width: 40px;
    height: 20px;
    border-radius: 50px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.toggle-switch.on {
    background-color: #27A083; /* Green when ON */
}

.toggle-switch.off {
    background-color: #ccc; /* Gray when OFF */
}

.toggle-slider {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 1px;
    left: 2px;
    transition: left 0.3s ease;
}

.toggle-switch.on .toggle-slider {
    left: 20px; /* Move to the right when ON */
}


.freeze-select {
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
    width: 70%;
}




