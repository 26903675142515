.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    height: 60px;
    border-bottom: 1px solid #eaeaea;
    margin-top: -1%;
    /* Updated */
}

.workspace-group-display {
    display: flex;
    align-items: center;
}

.workspace-group {
    font-size: 14px;
    font-family: 'Gelion Medium';
    letter-spacing: 0.4px;
    margin-right: 20px;
    margin-top: 20px;
    /* Reset to 0 to align properly */
}

.current-workspace {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: #fff;
}

.workspace-name {
    font-size: 14px;
    font-family: 'Gelion Regular';
    margin-left: 5px;
}

.workspace-switch-icon {
    margin-left: 5px;
    cursor: pointer;
}

.edit-workspace-icon {
    margin-left: 10px;
    font-size: 16px;
    color: #999;
    cursor: pointer;
}

.create-workspace-icon {
    margin-left: 10px;
    font-size: 18px;
    color: #999;
    cursor: pointer;
}

.separator {
    height: 30px;
    width: 1px;
    background-color: #eaeaea;
}

.top-bar-right {
    display: flex;
    align-items: center;
}

.invite-user-link {
    font-size: 14px;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.invite-icon {
    margin-right: 5px;
}

.form-group {
    margin-bottom: 15px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.char-count {
    font-size: 12px;
    color: #999;
}

.create-form-modal-button,
.switch-workspace-modal-button,
.invite-user-button,
.save-workspace-name-button,
.create-workspace-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-right: 10px;
    border-radius: 4px;
}

.create-form-modal-button:hover,
.switch-workspace-modal-button:hover,
.invite-user-button:hover,
.save-workspace-name-button:hover,
.create-workspace-button:hover {
    background-color: #333;
    border-color: #333;
}

.download-template-button,
.upload-json-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    color: #000000;
    border: 1px solid #eaeaea;
    padding: 10px 20px;
    margin-bottom: 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}

.download-template-button:hover,
.upload-json-button:hover {
    background-color: #f0f0f0;
    border-color: #d3d3d3;
}

.header-left-line {
    width: 4px;
    height: 40px;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
}

.file-display {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.uploaded-file-name {
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.delete-icon {
    margin-left: 10px;
    cursor: pointer;
    color: red;
    font-size: 16px;
}

.email-input,
.workspace-name-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.email-input:focus,
.workspace-name-input:focus {
    outline: none;
    border-color: black;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-right: 10px;
    border-radius: 4px;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}

.add-question-button,
.remove-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #e9f1fc;
    color: #000;
    border: 1px solid #e9f1fc;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
}

.add-question-button:hover,
.remove-button:hover {
    background-color: #d3e2fd;
    border-color: #d3e2fd;
}

.select-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.textarea-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    resize: none;
}

.workspace-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.workspace-item {
    padding: 10px 20px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, color 200ms ease 0ms;
}

.workspace-item:hover {
    background-color: #f5f5f5;
    color: #000;
}