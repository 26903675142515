.form-configurations-container {
    padding: 20px;
    margin-left: 2%;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: calc(100% - 40.5%);
    overflow-y: auto;
    margin: auto;
    margin-top: 3%;
}

h2 {
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Gelion Bold';
    font-size: 24px;
}

.form-title{
    font-size: 16px;
    font-family: 'Gelion Regular';
}

.form-subtitle{
    font-size: 14px;
}

.character-count{
    font-size: 13px;
    color: #999;
    text-align: right;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field,
.textarea-field,
.select-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.select-field {
    width: 50%;
}

.radio-group {
    display: flex;
    gap: 4px;
    margin-top: 10px;
}

.info-message {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
    margin-top: 10px;
}

.know-more {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
}

.know-more:hover {
    color: #000;
    font-weight: 600;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.mar-b10{
    margin-bottom: 10px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-right: 10px;
    border-radius: 6px;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}

.modal-content p {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #333;
    line-height: 1.6;
}

.date-group {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.date-group .date-field {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.create-audience-button {
    margin-top: 0;
    margin-bottom: 20px;
    background: #e9f1fc;
    color: #000;
    border: 1px solid #e9f1fc;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
}

.email-body-editor {
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 10px;
    font-family: 'Gelion Regular';
    font-size: 14px;
    min-height: 150px;
    background-color: #fff;
    overflow: scroll;
}

.email-body-editor .ql-container {
    min-height: 200px;
    /* Adjust the editor container height */
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.email-body-editor .ql-editor {
    min-height: 250px;
    /* Adjust the actual text area height */
}

/* Remove border from the toolbar */
.email-body-editor .ql-toolbar.ql-snow {
    border: none;
}

/* Remove border from the editor container */
.email-body-editor .ql-container.ql-snow {
    border: none;
}

/* Adjust the tooltip positioning relative to the selected text */
.email-body-editor .ql-tooltip {
    position: absolute;
    left: 6% !important;
    /* Reset any fixed positioning */
    top: 16% !important;
    /* Reset any fixed positioning */
    transform: none;
    /* Remove any centering transforms */
    width: auto;
    z-index: 10;
    white-space: nowrap;
    /* Prevent the tooltip from wrapping text */
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* Add a subtle shadow */
    border: 1px solid #ccc;
    /* Add a border for better visibility */
}

/* Ensure input field has proper spacing */
.email-body-editor .ql-tooltip input[type="text"] {
    width: 250px;
    /* Adjust the width if necessary */
    margin-right: 10px;
}

/* Style the "Save" button */
.email-body-editor .ql-tooltip .ql-action {
    margin: 0;
    padding: 5px 10px;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

/* Webhook Test Button and Result */
.inner-button {
    background: #e9f1fc;
    color: #000;
    border: 1px solid #e9f1fc;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 6px;
    margin-top: 0px;
    margin-bottom: 20px;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
}

.inner-button:hover {
    background-color: #cce4f7;
    border-color: #cce4f7;
}

.webhook-test-result {
    margin-top: 0px;
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #007bff;
}

.mar-bot-10{
    margin-bottom: 10px;
}

.textarea-field:focus {
    outline: 1.5px solid #333 !important;
}

.parameter-section {
    margin-bottom: 20px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.remove-button {
    background-color: #e74c3c;
    border: 1px solid #e74c3c;
    color: #fff;
    margin-top: 10px;
    transition: background-color 200ms ease 0ms;
}

.remove-button:hover {
    background-color: #c0392b;
    border-color: #c0392b;
}

.preload-config-group {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
}

.ace-github .ace_gutter{
    background: #e9f1fc;
    z-index: 0;
}