@import './fonts.css';

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 14%;
    max-width: 250px;
    background: linear-gradient(135deg, #e9f1fc 0%, #c3d9f7 100%);
    color: #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s;
}

.sidebar.collapsed {
    width: 60px;
    /* Adjust the width for the collapsed state */
}

.sidebar-toggle {
    position: absolute;
    top: 20px;
    right: -20px;
    background: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.profile-name,
.profile-email,
.account-id {
    display: none;
}

.sidebar:not(.collapsed) .profile-name,
.sidebar:not(.collapsed) .profile-email,
.sidebar:not(.collapsed) .account-id {
    display: block;
}

.sidebar.collapsed .account-id {
    /*display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: 'Gelion Regular';
    color: #6c757d;
    margin-bottom: 10px;*/
    display: none;
}

.sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-item {
    margin-bottom: 10px;
}

.sidebar-link {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px;
    font-family: 'Gelion Regular';
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar.collapsed .sidebar-icon {
    margin-right: 0;
    justify-content: center;
}

.sidebar-link:hover {
    background-color: #f5f5f5;
    color: #000;
    border-radius: 5px;
}

.sidebar-item.active .sidebar-link {
    background-color: #000;
    color: #ffffff;
}

.sidebar-item.active .sidebar-icon {
    color: #ffffff;
}

.sidebar-bottom {
    text-align: center;
}

.logout-button {
    background: none;
    border: none;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Gelion Regular';
    color: #000;
    transition: color 0.3s;
}

.logout-button:hover {
    color: #333;
}

.logout-icon {
    margin-right: 8px;
}

.app-version {
    font-size: 12px;
    margin-top: 5px;
}

.account-id {
    font-size: 12px;
    font-family: 'Gelion Regular';
    color: #6c757d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}