/* FormsTable.css */

.forms-table {
    padding: 20px;
}

.table-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.forms-table h2 {
    font-family: 'Gelion Medium';
    font-size: 18px;
}

.create-form-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease, box-shadow 200ms ease, border 200ms ease, color 200ms ease;
    border-radius: 6px;
}

.create-form-button:hover {
    background-color: #333;
    border-color: #333;
}

.table-grid {
    display: grid;
    gap: 10px;
}

.table-header {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f7f7f8;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Gelion Medium';
    cursor: pointer;
}

.table-header div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-row {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    align-items: center;
    border: 1px solid #eaeaea;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Gelion Regular';
    background-color: #ffffff;
    transition: background-color 200ms ease, transform 200ms ease;
}

.form-row:nth-child(even) {
    background-color: #f9f9f9;
}

.form-row:hover {
    background-color: #e9f1fc;
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}

.actions {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 14px;
    /* Adjust the spacing between form name and actions */
}

.actions a,
.actions button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
    font: inherit;
    transition: color 200ms ease;
}

.actions a:hover,
.actions button:hover {
    color: #000;
    font-weight: 600;
    /* Change color on hover */
}

.actions button.delete-button {
    color: red;
}

.actions button.delete-button:hover {
    color: darkred;
    /* Change color on hover */
}

.actions span {
    font-size: 14px;
    font-family: 'Gelion Regular';
}

.link-icon {
    display: flex;
    justify-content: center;
}

.forms-table-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    max-width: 48%;
    margin: 14% auto;
}

.forms-table-empty h2 {
    font-size: 20px;
    font-family: 'Gelion Medium';
    color: #333;
    margin-bottom: 10px;
}

.forms-table-empty p {
    font-size: 16px;
    font-family: 'Gelion Regular';
    color: #666;
}