.form-submitted-container {
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 50%;
    overflow-y: auto;
}

h2 {
    text-align: left;
    margin-bottom: 10px;
    font-family: 'Gelion Bold';
    font-size: 24px;
}

p {
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Gelion Regular';
    font-size: 16px;
}

.json-output {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    overflow: auto;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-top: 6px;
    border-radius: 6px;
    width: 100%;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}

.form-preview-container {
    margin-top: 20px;
    text-align: left;
}

.form-logo {
    max-width: 100px;
    margin-bottom: 20px;
    display: block;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.follow-up-question {
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background: #f9f9f9;
}

label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.select-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.file-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.file-input {
    display: none;
}

.file-input-label {
    background: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.file-input-label:hover {
    background-color: #333;
}

.options-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.option-label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.option-input {
    margin-right: 10px;
}

.input-field[type="date"] {
    width: 20%;
}