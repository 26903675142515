.form-list-container {
    padding: 20px;
    margin-left: 2%;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: calc(100% - 20%);
    overflow-y: auto;
    margin: auto;
    margin-top: 3%;
}

h2 {
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Gelion Bold';
    font-size: 24px;
}

.form-list-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Gelion Regular';
    font-size: 14px;
    table-layout: fixed;
}

.form-list-table th,
.form-list-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-list-table th {
    background-color: #f2f2f2;
    font-family: 'Gelion Bold';
}

.form-list-table tr:hover {
    background-color: #f5f5f5;
}

.action-icon {
    margin-right: 15px;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: color 200ms ease-in-out;
    text-decoration: none;
}

.action-icon:hover {
    color: #333;
    /* Change the icon to filled version on hover */
    transform: scale(1.1);
}

/* Specific styling for the delete icon */
.delete-icon:hover {
    color: #ff1a1a;
    transform: scale(1.1);
}
