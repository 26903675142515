.settings-container {
    padding: 20px;
    margin-left: 2%;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: calc(100% - 40.5%);
    overflow-y: auto;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    font-family: 'Gelion Bold';
    font-size: 16px;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
}

.tabs button.active {
    border-bottom: 2px solid #000;
}

.email-settings,
.general-settings {
    display: flex;
    flex-direction: column;
}

.provider-selection {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.provider-option {
    border: 2px solid #eaeaea;
    padding: 10px;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 6px;
}

.provider-option img {
    max-width: 100px;
    max-height: 50px;
}

.provider-option.selected {
    border-color: #000;
}

.context-info {
    margin-bottom: 20px;
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
}

.form-group {
    margin-bottom: 20px;
}

label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    margin-right: 10px;
    border-radius: 6px;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}