@font-face {
    font-family: 'Gelion Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Regular Italic'), url(./../fonts/gelion/GelionRegularItalic.woff) format('woff');
}

@font-face {
    font-family: 'Gelion Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Regular'), url(./../fonts/gelion/GelionRegular.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Light'), url(./../fonts/gelion/GelionLight.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Thin Italic'), url(./../fonts/gelion/GelionThinItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Light Italic'), url(./../fonts/gelion/GelionLightItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Thin'), url(./../fonts/gelion/GelionThin.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Medium Italic'), url(./../fonts/gelion/GelionMediumItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Medium'), url(./../fonts/gelion/GelionMedium.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion SemiBold Italic'), url(./../fonts/gelion/GelionSemiBoldItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion SemiBold'), url(./../fonts/gelion/GelionSemiBold.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Bold Italic'), url(./../fonts/gelion/GelionBoldItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Bold'), url(./../fonts/gelion/GelionBold.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Black Italic'), url(./../fonts/gelion/GelionBlackItalic.woff) format('woff');
}


@font-face {
    font-family: 'Gelion Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Black'), url(./../fonts/gelion/GelionBlack.woff) format('woff');
}