/* SurveyResult.css */

@import './fonts.css';

.survey-result {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.survey-result-header {
    font-family: 'Gelion Medium';
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab-button {
    font-family: 'Gelion Regular';
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
}

.tab-button.active {
    border-bottom: 2px solid #000;
}