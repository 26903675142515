body {
    font-family: 'Gelion Regular';
    background-color: #fff;
    margin: 0;
    padding: 0;
}


.header div {
    padding-bottom: 20px;
}

.header img {
    width: 70px !important;
    margin-top: 3%;
}

.header h1 {
    font-size: 24px;
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
}

.header p {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 3%;
    font-family: 'Gelion Regular';
}



.container {
    width: 80%;
    margin: auto;
    max-width: 800px;
}

/*

.input-div {
    margin-top: 1%;
}

form {
    background-color: #fff;
    border-radius: 8px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Gelion Bold';
}

label {
    display: block;
    margin: 20px 0 10px;
    font-family: 'Gelion Regular';
    font-size: 15px;
    line-height: 1.625em;
}

input[type="text"],
select {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    -webkit-transition: border 0.2s ease 0s, color 0.2s ease 0s;
    transition: border 0.2s ease 0s, color 0.2s ease 0s;
    box-sizing: border-box;
    font-size: 15px;
    margin-bottom: 20px;
    font-family: 'Gelion Regular';
}

input[type="text"]:focus,
select:focus,
input[type="text"]:focus-visible,
select:focus-visible {
    border-color: #333;
}

input[type="submit"] {
    box-sizing: border-box;
    display: inline-block;
    line-height: calc(2.5 * 16px);
    border-radius: 6px;
    font-weight: 400;
    font-size: calc(0.875 * 16px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-transform: capitalize;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    position: relative;
    overflow: hidden;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: none;
    --geist-ui-button-icon-padding: calc(0.727 * 16px);
    --geist-ui-button-height: calc(2.5 * 16px);
    --geist-ui-button-color: #fff;
    --geist-ui-button-bg: #000;
    min-width: calc(10.5 * 16px);
    width: initial;
    height: calc(2.5 * 16px);
    padding: 0 calc(1.375 * 16px) 0 calc(1.375 * 16px);
    margin: 0 0 0 0;
}

input[type="submit"]:hover {
    background-color: #333;
    border-color: #333;
}



.option-group {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 0px 10px 10px 10px;
    margin-bottom: 20px;
}

.option-group input[type="radio"],
.option-group input[type="checkbox"] {
    margin-right: 10px;
    outline: none;
    top: 2px;
    position: relative;
}

.option-group label {
    display: inline-block;
    margin-right: 10px;
    font-size: 13px;
}

input[type="text"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
    outline: none !important;
    color: #000 !important;
}


.option-group label {
    align-items: center;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #eaeaea;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #add8e6;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='checkbox']:after {
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #eaeaea;
}

input[type='checkbox']:checked {
    background-color: #333;
    content: '';
    display: inline-block;
    visibility: visible;
}



*/

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #eaeaea;
    display: inline-block;
    padding: 6px 10px 6px 10px;
    cursor: pointer;
    border-radius: 6px;
    font-family: 'Gelion Regular';
    font-size: 15px;
    line-height: 1.625em;
    width: 30%;
    text-align: center;
    box-sizing: border-box;
    -webkit-transition: border 0.2s ease 0s, color 0.2s ease 0s;
    transition: border 0.2s ease 0s, color 0.2s ease 0s;
    margin-top: 0;
    margin-bottom: 4%;
}

.custom-file-upload:hover {
    border-color: #333;
}