body,
html {
    height: 100%;
    margin: 0;
}

.signup-container,
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.signup-box,
.login-box {
    background: #fff;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    width: 400px;
    margin-bottom: 20px;
}

.signup-box h2,
.login-box h2 {
    font-family: 'Gelion Bold';
    font-size: 24px;
    margin-bottom: 20px;
}

.signup-box p,
.login-box p {
    font-family: 'Gelion Regular';
    font-size: 14px;
    margin-bottom: 30px;
    color: #6c757d;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
}

label {
    margin-bottom: 5px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.country-select-field {
    appearance: none;
    background: #fff url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 6.345c-.646-.647-.183-1.745.707-1.745h9.684c.89 0 1.354 1.098.708 1.745l-4.796 4.796a1 1 0 0 1-1.414 0z"/></svg>') no-repeat right 10px center;
    background-size: 12px 12px;
    padding-right: 30px;
    border: none !important;
    padding: 0 !important;
}

.error-message {
    color: red !important;
    font-size: 14px !important;
    margin-top: 5px !important;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    border-radius: 6px;
    width: 100%;
    margin-top: 10px;
}

.button:hover {
    background-color: #333;
    border-color: #333;
}

.skip-link {
    margin-top: 20px;
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}

.login-link,
.signup-link,
.resend-otp {
    margin-top: 20px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.login-link span,
.signup-link span,
.resend-otp span {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}

.password-group {
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 34px;
    cursor: pointer;
    font-size: 18px;
    color: #999;
}

.footer {
    text-align: center;
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
    position: fixed;
    bottom: 10px;
    width: 100%;
}

.footer p {
    text-align: center;
    margin-bottom: 10px;
}

.resend-otp .disabled {
    cursor: not-allowed;
    color: #999;
}

.password-tooltip {
    position: absolute;
    top: 60px;
    left: 0;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 10px;
    width: 94.5%;
    z-index: 10;
}

.password-conditions {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Gelion Regular';
    font-size: 12px;
    color: #6c757d;
}

.password-conditions li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.password-conditions li.valid {
    color: green;
}

.password-conditions li.invalid {
    color: red;
}

.password-conditions li svg {
    margin-right: 5px;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
}

.radio-group label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.country-option {
    display: flex;
    align-items: center;
}

.country-flag {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

.react-select__control {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.react-select__menu {
    z-index: 20;
}

.react-select__option {
    display: flex;
    align-items: center;
}