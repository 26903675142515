/* GraphicalInsights.css */

.insights-container {
    padding: 20px;
    background-color: #fff;
    margin: 0 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    width: calc(100% - 20.5%);
    overflow: scroll;
}

/* Client Selection Dropdown */
.client-selection {
    margin-bottom: 20px;
}

.client-selection select {
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

/* Insight Grid */
.insight-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
}

/* Insight Cards */
.card {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.client-info {
    text-align: center;
}

.avatar-placeholder {
    margin-bottom: 10px;
}

.avatar-circle {
    width: 80px;
    height: 80px;
    background-color: #007bff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 32px;
    margin: 0 auto;
}

.info-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.info-item {
    padding: 10px;
    border-radius: 4px;
    background-color: #f8f9fa;
    font-size: 14px;
}

.negative-pin-code {
    background-color: #f8d7da;
    color: #721c24;
}

.positive {
    background-color: #d4edda;
    color: #155724;
}

.neutral {
    background-color: #e2e3e5;
    color: #383d41;
}

.negative {
    background-color: #f8d7da;
    color: #721c24;
}

.health-score-label,
.sanitation-score-label,
.claim-score-label,
.smoking-impact-label,
.alcohol-impact-label {
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th, table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.doughnut-chart {
    margin: 0 auto;
}

/* Existing Graph Sections */
.insight-section {
    margin-bottom: 40px;
}

.insight-section h3 {
    font-family: 'Gelion Bold';
    font-size: 20px;
    margin-bottom: 10px;
}

.insight-section p {
    font-family: 'Gelion Medium';
    font-size: 14px;
    color: #333;
}

/* Chart Containers */
.canvas-container {
    margin-top: 20px;
}

.chart-container {
    position: relative;
    height: 400px;
}

.radar-chart, .bar-chart, .line-chart {
    margin-bottom: 40px;
}
