/* FormConfiguration.css */

@import './fonts.css';

.form-configuration {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0;
    width: 40%;
}

.section {
    margin-bottom: 20px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    overflow: hidden;
}

.section.expanded .section-content {
    display: block;
}

.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f7f7f8;
    cursor: pointer;
}

.section-title {
    font-family: 'Gelion Regular';
    font-size: 16px;
    margin: 0;
}

.section-content {
    display: none;
    padding: 20px;
    padding-top: 0;
}

.section-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
}

.save-button {
    font-family: 'Gelion Regular';
    font-size: 14px;
    border: none;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #333;
}

.form-control {
    margin-bottom: 10px;
}

.label {
    display: block;
    font-family: 'Gelion Regular';
    font-size: 14px;
    margin-bottom: 5px;
}

.input-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

.input-field:focus {
    outline: 1.5px solid #333 !important;
}

.css-t3ipsp-control {
    border-color: #333 !important;
    box-shadow: 0 0 0 1px #333 !important;
}

.css-t3ipsp-control:hover {
    border-color: #333 !important;
    box-shadow: 0 0 0 1px #333 !important;
}



.date-input {
    max-width: 200px;
}