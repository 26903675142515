/* FormBuilder.css */

.dashboard {
    display: flex;
    height: 100vh;
    background: #fafafa;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fafafa;
    margin-left: 18%;
}

.form-builder-container {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.initial-components,
.form-components {
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    background-color: #f9f9f9;
    min-height: 400px;
}

.initial-components {
    width: 30%;
    background-color: #f0f4f8;
}

.form-components {
    width: 70%;
    background-color: #ffffff;
}

.clickable-component,
.form-component {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: 'Gelion Regular';
    font-size: 14px;
    transition: background-color 200ms ease, transform 200ms ease;
    cursor: pointer;
}

.clickable-component:hover,
.form-component:hover {
    background-color: #e9f1fc;
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
}

.form-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-component-label {
    width: 70%;
    border: none;
    border-bottom: 1px solid #eaeaea;
    font-family: 'Gelion Medium';
    font-size: 14px;
}

.form-component-label:focus {
    outline: none;
    border-bottom: 1px solid #333;
}

.form-component-controls button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-component-controls button:hover {
    color: #333;
}

.form-component label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Gelion Medium';
    font-size: 14px;
}

.initial-components h3,
.form-components h3 {
    margin-bottom: 20px;
    font-family: 'Gelion Bold';
    font-size: 18px;
}

.form-component-options {
    margin-top: 10px;
}

.form-component-options button {
    margin-top: 5px;
    padding: 5px 10px;
    border: none;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-component-options button:hover {
    background-color: #555;
}

.form-component-options div {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.form-component-options div input[type="text"] {
    width: 70%;
    padding: 5px;
    margin-right: 5px;
    border-radius: 4px;
    border: 1px solid #eaeaea;
}

.form-component-options div button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
}

.form-component-options div button:hover {
    color: #b00;
}