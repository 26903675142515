/* ReportSettings.css */

.report-settings-container {
    margin-left: 2%;
    background: #fff;
    width: calc(100% - 20.5%);
    margin-top: 2%;
    overflow-y: visible;
}

.report-header {
    padding: 10px 0;
    border-bottom: 1px solid #e9e9eb;
    margin-bottom: 20px;
}

.report-title {
    font-family: 'Gelion Bold';
    font-size: 24px;
    margin: 0;
}

.report-description {
    font-family: 'Gelion Regular';
    font-size: 14px;
    color: #6c757d;
    margin-top: 5px;
}

.table-container {
    overflow-x: auto;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #e9e9eb;
    padding: 10px;
    text-align: left;
    font-family: 'Gelion Regular';
    font-size: 14px;
}

thead {
    background-color: #f7f7f8;
}

button:hover {
    background-color: #333;
    border-color: #333;
}

.no-schedule-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: #333;
}

.no-schedule-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.no-schedule-text {
    font-family: 'Gelion Medium';
    font-size: 16px;
    margin-bottom: 0;
}

/* Modal Styles */
.custom-modal {
    background: #fff;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    border-radius: 8px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.custom-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.form-group {
    margin-bottom: 20px;
}

.input-field,
.select-field {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    font-family: 'Gelion Regular';
    font-size: 14px;
    box-sizing: border-box;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.button-cancel {
    background: #ccc;
    border: 1px solid #ccc;
    margin-left: 10px;
}

.button-cancel:hover {
    background: #bbb;
}

.action-button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
}

.action-button:hover {
    background-color: #333;
    color: #fff;
}

.pause-button {
    margin-right: 5px;
    background-color: #fcf7f8;
    color: #000;
    border: none;
    font-size: 13px;
}

.pause-button:hover {
    background-color: #f0ebec;
    border: none;
}

.delete-button {
    background-color: #F08080;
    color: white;
    border: none;
    font-size: 13px;
}

.delete-button:hover {
    background-color: #CD5C5C;
    border: none;
}

.mar-t-2p{
    margin-top: 2%;
}

.parameter-group-settings{
    margin-bottom: 0;
    margin-top: 10px;
}

.scheduler-modal-input{
    margin-top: 6px;
    margin-bottom: 6px;
}

.modal-input-description{
    font-size: 14px;
    margin-bottom: 6px;
}

.mar-t-10{
    margin-top: 10px;
}
