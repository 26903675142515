/* Dashboard.css */

.dashboard {
    display: flex;
    height: 100vh;
    background: #fafafa;
    transition: margin-left 0.3s;
}

.sidebar-collapsed .main-content {
    margin-left: 60px;
    /* Adjust based on the collapsed sidebar width */
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fafafa;
    margin-left: 14%;
    padding-top: 20px;
    transition: margin-left 0.3s;
    /* Ensure smooth transition */
}

.sidebar-collapsed .main-content {
    margin-left: 120px;
    /* Ensure content doesn't hide behind the sidebar */
}